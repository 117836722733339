import React from 'react';
import '../scss/managers-opinions.scss';
import Title from './title';
import { ManagerInterface } from '../models/Manager';
import Carrousel from './carrousel';

export interface ManagersOpinionsProps {}

const MANAGERS: ManagerInterface[] = [
  {
    name: 'Miguel Taveras',
    description:
      '“Pocas organizaciones preparan a sus colaboradores con la pasión, disciplina y herramientas como lo hace Proyectat. Es un honor ser aliado estratégico de esta gran empresa”.',
    image: '/images/manager_1.png',
    jobTitle: 'Chief Sales Officer Hoppis Performance Network',
    LinkedinLink: 'https://www.linkedin.com/in/miguel-taveras/',
  },
  {
    name: 'Miguel Martinez',
    description:
      '“Proyectat es para mí, sencillamente la forma correcta de vender seguros de vida.”.',
    image: '/images/manager_2.png',
    jobTitle: 'Subdirector del Instituto Nacional de Seguros (INS) - FASECOLDA',
    LinkedinLink:
      'https://www.linkedin.com/in/miguel-francisco-martinez-briceno/',
  },
  {
    name: 'Gina Castañeda',
    description:
      '“Proyectat marca un hito muy importante en la industria aseguradora porque es una compañía jóven con muy buenos resultados y un futuro bastante promisorio”.',
    image: '/images/manager_3.png',
    jobTitle: 'Gerente de Relacionamiento para Latinoamérica en LIMRA',
    LinkedinLink: 'https://www.linkedin.com/in/gina-casta%C3%B1eda-0496561a/',
  },
];

const ManagersOpinions: React.FC<ManagersOpinionsProps> = props => {
  return (
    <section className="Managers__container">
      <Title
        text="Lo que dicen los mejores de los seguros"
        className="Managers__title"
      />
      <Carrousel source={MANAGERS} />
    </section>
  );
};

export default ManagersOpinions;
