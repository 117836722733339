import React from 'react';
import '../scss/welcome-image.scss';

export interface WelcomeImageProps {}

const WelcomeImage: React.FC<WelcomeImageProps> = props => {
  return (
    <section className="Welcome__image">
      <p  className="Welcome__image-paragraph">
        En Proyectat innovamos todos los días para convertir tus preocupaciones
        en tranquilidad.
      </p>
    </section>
  );
};

export default WelcomeImage;
