import React from 'react';
import '../scss/our-history.scss';
import Title from './title';
import { HistoryItemInterface } from '../models/HistoryItem';

export interface OurHistoryProps {}

const HISTORY_ITEMS: HistoryItemInterface[] = [
  {
    year: '2017',
    description: 'Iniciamos operaciones en Colombia',
    image: '/images/life-cycle-1.png',
  },
  {
    year: '2018',
    description: 'Lanzamos el producto más innovador en el mercado colombiano',
  },
  {
    year: '2019',
    description: 'Aseguramos los sueños de más de 3000 colombianos.',
    image: '/images/life-cycle-2.png',
  },
  {
    year: '2019',
    description:
      'Reconocimiento de Aspro GAMA, LAMP 2019, “Empresa de Desarrollo Consolidado” <br /> Categoría ORO',
    image: '/images/life-cycle-3.png',
  },
  {
    year: '2020',
    description: 'Participación en MDRT 2020 con 3 Miembros y 2 Aspirantes.',
    image: '/images/life-cycle-4.png',
  },
];

const OurHistory: React.FC<OurHistoryProps> = props => {
  return (
    <section className="OurHistory__container">
      <Title text="Nuestro recorrido" className="OurHistory__title" />
      {HISTORY_ITEMS.map((ITEM, index) => (
        <div
          className={
            (!!ITEM.image ? '' : 'no__image') + ' OurHistory__item'
          }
          key={`history-item-${index}`}
        >
          <div className="OurHistory__item-image">
            <img src={ITEM.image} alt="" className="OurHistory__item-img" />
          </div>
          <div className="OurHistory__item-description">
            <p className="OurHistory__item-year">{ITEM.year}</p>
            <p
              className="OurHistory__item-paragraph"
              dangerouslySetInnerHTML={{ __html: ITEM.description }}
            ></p>
          </div>
        </div>
      ))}
      <div className="OurHistory__separator"></div>
    </section>
  );
};

export default OurHistory;
