import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Banner from '../components/banner';
import WelcomeMessage from '../components/welcome-message';
import WelcomeImage from '../components/welcome-image';
import AlianceValueProposalAndVideo from '../components/aliance-value-proposal-and-video';
import Map from '../components/map';
import ScheduleBanner from '../components/schedule-banner';
import ManagersOpinions from '../components/managers-opinions';
import OurHistory from '../components/our-history';

export interface AboutProps {}

const About: React.FC<AboutProps> = () => (
  <div className="screen__container">
    <Layout>
      <SEO title="Contacto" />
      <Banner
        title="Proyectat es disruptiva y sostenible"
        subtitle="Somos la agencia máster de mayor crecimiento y sostenibilidad en el tiempo de la región."
        image="/images/Proyectat-05.png"
        mobileBackgroundColor="#6DA3D5"
        hasButton={false}
      ></Banner>
      <WelcomeMessage />
      <WelcomeImage />
      <AlianceValueProposalAndVideo />
      <OurHistory />
      <ScheduleBanner />
      <ManagersOpinions />
      <Map />
    </Layout>
  </div>
);

export default About;
