import React, { useState } from 'react';
import '../scss/aliance-value-proposal-and-video.scss';
import Title from './title';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';

export interface AlianceValueProposalAndVideoProps {}

const AlianceValueProposalAndVideo: React.FC<AlianceValueProposalAndVideoProps> = props => {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <section className="Aliance__container">
      <Title text="Nuestros mejores aliados" className="Aliance__title" />
      <div className="Description__container">
        <div className="Image__container">
          <img
            src="/images/31.png"
            alt="Imagen de bienvenida"
            className="Image-img"
          />
        </div>
        <p className="Description__paragraph desktop__justify">
          Proyectat cuenta con el respaldo de aseguradoras de larga trayectoria
          en la región y de calificación AA que consolidan el valor de los
          productos que comercializamos.
          <br />
          <br />
          Somos los únicos colombianos que hacen parte de MDRT, Million Dollar
          Round Table que reúne a los mejores profesionales de servicios
          financieros y de seguros de vida más importantes del mundo. Esta
          membresía es reconocida internacionalmente como el estándar de
          excelencia en el negocio de seguros de vida. Diseñamos nuestro MBA
          enfocado en el sector con respaldo de EU School.
        </p>
      </div>
      <div className="Aliance__brands">
        <img src="/images/aliance-1.jpeg" alt="Logos de aliados" id="gama" />
        <img src="/images/aliance-2.jpg" alt="Logos de aliados" id="aspro" />
        <img src="/images/aliance-3.jpg" alt="Logos de aliados" id="mdrt" />
        <img src="/images/aliance-4.png" alt="Logos de aliados" id="hoopis" />
        <img
          src="/images/aliance-5.png"
          alt="Logos de aliados"
          id="trustworthy"
        />
        <img src="/images/aliance-6.png" alt="Logos de aliados" id="euschool" />
      </div>
      <Title text="Nuestra propuesta de valor" className="Aliance__title" />
      <div className="Description__container">
        <div className="Image__container">
          <img
            src="/images/32.png"
            alt="Imagen de bienvenida"
            className="Image-img"
          />
        </div>
        <p className="Description__paragraph desktop__justify">
          Somos expertos en la creación de canales de distribución en vida
          individual y Worksite Marketing (WSM) en Colombia y Latam. Soportados
          con desarrollo comercial que nos permite manejar la línea completa de
          creación de equipos para lograr un alto desempeño, desarrollo
          permanente del talento, crecimiento sostenido y rentabilidad.
        </p>
      </div>
      <Title
        text="Un video que habla por nosotros"
        className="Aliance__title"
      />
      {showVideo ? (
        <ReactPlayer
          controls
          playing
          url="https://youtu.be/WO9YwfIGEtA"
          className="Aliance__video"
          onEnded={() => setShowVideo(false)}
        />
      ) : (
        <div
          className="Aliance__video Aliance__video-container"
          onClick={() => setShowVideo(true)}
        >
          <img
            src="/images/video-image.jpg"
            alt="Un video que habla por nosotros"
            className="Aliance__video"
          />
          <div className="Aliance__video-play_button">
            <FontAwesomeIcon className="mr-3" size="lg" icon={faPlayCircle} />
          </div>
        </div>
      )}
    </section>
  );
};

export default AlianceValueProposalAndVideo;
