import React from 'react';
import '../scss/map.scss';
import Title from './title';

export interface MapProps {}

const Map: React.FC<MapProps> = props => {
  return (
    <section className="Map__container container">
      <Title text="Nuestra presencia" />
      <img
        src="/images/mapa.png"
        alt="Mapa de nuestra presencia"
        className="Map__container-image"
      />
      <p className="Map__paragraph">
        Contamos con cobertura y atención financiera especializada en las
        principales ciudades de Colombia
      </p>
    </section>
  );
};

export default Map;
