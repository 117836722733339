import React from 'react';
import '../scss/welcome-message.scss';

export interface WelcomeMessage {}

const WelcomeMessage: React.FC<WelcomeMessage> = props => {
  return (
    <section className="Welcome__container">
      <div className="Welcome__image-container">
        <img
          src="/images/30.png"
          alt="Imagen de bienvenida"
          className="Welcome__image-img"
        />
      </div>
      <p className="Welcome__paragraph mobile__justify">
        Bienvenidas y bienvenidos a Proyectat, la primera agencia máster de
        seguros en el país que desde su creación, no ha parado de crecer y
        revolucionar la forma de disfrutar de los seguros.
        <br /><br />
        Somos una familia de consultores patrimoniales de alto desempeño que
        sumamos cientos de años de experiencia en proteger la tranquilidad de
        nuestros clientes. Ofrecemos productos innovadores que mejoran la
        calidad de vida financiera tanto de nuestros aliados como de nuestros
        asegurados.
      </p>
    </section>
  );
};

export default WelcomeMessage;
