import React from 'react';
import '../scss/schedule-banner.scss';
import Button from './button';
import { connect } from 'react-redux';
import { handleShowModalQuote } from '../redux/actions';

export interface ScheduleBannerProps {
  dispatch: any;
}

const ScheduleBanner: React.FC<ScheduleBannerProps> = props => {
  const showModalQuote = () => props.dispatch(handleShowModalQuote(true));
  return (
    <section className="Schedule__container">
      <div className="Schedule__description">
        <h3 className="Schedule__description-title">
          Comienza a construir tu futuro con nosotros
        </h3>
        <p className="Schedule__description-paragraph">
          ¡Da el primer paso y agenda una asesoría con los mejores consultores
          patrimoniales!
        </p>
        <Button
          fontSize="21px"
          paddingLeft="97px"
          paddingRight="97px"
          className="Schedule__button"
          onClickEvent={showModalQuote}
        >
          Agendar ahora
        </Button>
      </div>
      <img src="/images/Proyectat-15.png" alt="" className="Schedule__image" />
    </section>
  );
};

export default connect(state => ({ showModalQuote: state.showModalQuote }))(
  ScheduleBanner
);
